const content = document.querySelectorAll('.studio__tab');
const tab = document.querySelectorAll('.studio-tab-trigger');
const tabs = document.querySelector('.studio__tabs');
const body = document.querySelector('body');
const tabOne = document.querySelector('.cost-tab-one');
const tabTwo = document.querySelector('.cost-tab-two');

function hideStudioTabContent() {
  content.forEach(item => {
    item.style.display = 'none';
  });
  tab.forEach(item => {
    item.classList.remove('switcher__button--active');
  });
}

function showStudioTabContent(i = 0) {
  if (content[i] && tab[i]) {
    content[i].style.display = 'block';
    tab[i].classList.add('switcher__button--active');
  }

  if (tabOne?.classList.contains('switcher__button--active')) {
    body.classList.add('cost-pink');
    body.classList.remove('cost-purple');
  }

  if (tabTwo?.classList.contains('switcher__button--active')) {
    body.classList.remove('cost-pink');
    body.classList.add('cost-purple');
  }
}

hideStudioTabContent();
showStudioTabContent();

tabs?.addEventListener("click", (e) => {
  const target = e.target
  if (target) {
    tab.forEach((item, i) => {
      if (target == item) {
        hideStudioTabContent();
        showStudioTabContent(i);
      }
    })
  }
})
