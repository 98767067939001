import "../../node_modules/virtual-select-plugin/dist/virtual-select";
import "../../node_modules/virtual-select-plugin/dist/virtual-select.min.css";

VirtualSelect.init({ 
  dropboxWrapper: 'body',
  ele: '#select-place',
  hideClearButton: true,
  zIndex: 20,
  showDropboxAsPopup: false,
});

VirtualSelect.init({
  dropboxWrapper: 'body',
  ele: '#select-trainer',
  hideClearButton: true,
  zIndex: 20,
  showDropboxAsPopup: false,
  search: true,
  searchPlaceholderText: "Поиск тренеров",
  noSearchResultsText: "Ничего не найдено",
});

VirtualSelect.init({
  dropboxWrapper: 'body',
  ele: '#select-training',
  hideClearButton: true,
  zIndex: 20,
  showDropboxAsPopup: false,
  search: true,
  searchPlaceholderText: "Поиск тренеров",
  noSearchResultsText: "Ничего не найдено",
});

VirtualSelect.init({
  dropboxWrapper: 'body',
  ele: '#select-time',
  hideClearButton: true,
  zIndex: 20,
  showDropboxAsPopup: false,
});

VirtualSelect.init({
  dropboxWrapper: 'body',
  ele: '#select-place-cost',
  hideClearButton: true,
  zIndex: 20,
  showDropboxAsPopup: false,
});

VirtualSelect.init({
  dropboxWrapper: 'body',
  ele: '#select-classes-cost',
  hideClearButton: true,
  zIndex: 20,
  showDropboxAsPopup: false,
});

VirtualSelect.init({
  dropboxWrapper: 'body',
  ele: '#select-time-cost',
  hideClearButton: true,
  zIndex: 20,
  showDropboxAsPopup: false,
  position: 'bottom right',
});

VirtualSelect.init({
  dropboxWrapper: 'body',
  ele: '#select-registration',
  hideClearButton: true,
  zIndex: 20,
  showDropboxAsPopup: false,
  dropboxWidth: 'auto',
  additionalClasses: 'select-registration',
});

const selectRegistration = document.querySelector('#select-registration');
const selectRegistrationAll = document.querySelector('#select-registration-all input');

const setChangeSelect = () => {
  selectRegistrationAll.checked = false;
};

const setDefaultOption = () => {
  selectRegistration.reset();

  setTimeout(() => {
    selectRegistrationAll.checked = true;
  }, '200')
};

selectRegistration?.addEventListener('change', setChangeSelect);
selectRegistrationAll?.addEventListener('click', setDefaultOption);

if (selectRegistrationAll) {
  setDefaultOption();
}

const selectsCost = document.querySelector('.cost__selects');
const selectPlaceCost = document.querySelector('#select-place-cost');
const selectTimeCost = document.querySelector('#select-time-cost');

const setSelectsScrollToLeft = () => {
  setTimeout(() => {
    selectsCost.scrollBy({
      top: 0,
      left: -150,
      behavior: 'smooth'
    });
  }, '150')
};

const setSelectsScrollToRight = () => {
  setTimeout(() => {
    selectsCost.scrollBy({
      top: 0,
      left: 150,
      behavior: 'smooth'
    });
  }, '150')
};

selectPlaceCost?.addEventListener('click', setSelectsScrollToLeft);
selectTimeCost?.addEventListener('click', setSelectsScrollToRight);
