//Открытие выпадающих списков
const buttonOpenAnswer = document.querySelectorAll('.landing__question-heading');
const buttonOpenToggleItem = document.querySelectorAll('.landing__toggle-item-heading');

function openToggleElement(buttons) {
  buttons.forEach((button) => {
    let buttonClassName = button.className;
    let hiddenContent = button.nextElementSibling;
    let hiddenContentClassName = hiddenContent.className;

    button.addEventListener('click', () => {
      button.classList.toggle(`${buttonClassName}_content-visible`)
      hiddenContent.classList.toggle(`${hiddenContentClassName}_visible`);

      if (hiddenContent.style.maxHeight) {
        hiddenContent.style.maxHeight = null;
      } else {
        hiddenContent.style.maxHeight = hiddenContent.scrollHeight + "px";
      }
    })
  })
};

openToggleElement(buttonOpenToggleItem);
openToggleElement(buttonOpenAnswer);

const players = document.querySelectorAll('.landing__player');

const classNameButtonSongIsPlay = "landing__play-btn landing__files-icon landing__files-icon_stop";
const classNameButtonSongIsStop = "landing__play-btn landing__files-icon landing__files-icon_play";

document.addEventListener("DOMContentLoaded", (e)=> {

  players.forEach((player) => {

    //dom объекты элементов контроля
    const av = player.querySelector(".landing__audio");
    const playTime = player.querySelector(".landing__play-time");
    const playBtn = player.querySelector(".landing__play-btn");
    const curTime = player.querySelector(".landing__play-time-select");
  
    function getFullTime(timeInSecind) {
      const sec_num = timeInSecind;
      const hours   = Math.floor(sec_num / 3600);
      const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);
      return [hours, minutes, seconds=Math.round(seconds)];
    }

    av.onloadedmetadata = function() {
      curTime.max = av.duration;
      const [hours, minutes, seconds] = getFullTime(av.duration);

      playTime.innerHTML = minutes+':'+seconds
    };

    //функция вывода текущего времени воспроизведения
    av.ontimeupdate=function() {
      let [hours, minutes, seconds] = getFullTime(av.currentTime);

      if (hours < 10) {
        hours = "0"+hours;
      }
      if (minutes < 10) {
        minutes = "0"+minutes;
      }
      if (seconds < 10) {
        seconds = "0"+seconds;
      }
      
      playTime.innerHTML = minutes+':'+seconds; 

      if(!av.paused) curTime.value=av.currentTime; 
    }; 
  
    //функция для установки начала воспроизведения
    curTime.onchange=function() { 
      av.pause();
      av.currentTime = curTime.value;
      playBtn.className = classNameButtonSongIsPlay;
      av.play();
    };

      //функция для play/pause и изображения кнопки воспроизведения
      playBtn.addEventListener("click", (a)=> {

      if(!av.paused)
      {
        av.pause();
        playBtn.className = classNameButtonSongIsStop;
      }
      else
      {
        document.querySelectorAll('.landing__audio').forEach((audio) => {
          if(!audio.paused) {
            audio.pause()
            audio.nextElementSibling.className = classNameButtonSongIsStop;
          }
          av.play();
          playBtn.className =  classNameButtonSongIsPlay;
          curTime.classList.add("landing__play-time-select_visible")
        })
      }
    });
  })
});

const landingTable = document.querySelector('.landing__table');

if(landingTable) {
  const tableHead = landingTable.tHead.getElementsByTagName('th');
  landingTable.style = `grid-template-columns: repeat(${tableHead.length}, minmax(111px, 1fr))`
};

//Открытие модальных окон с изображениями

const buttonsOpenImageModal = document.querySelectorAll('.landing__btn-open-modal');
const imageModalWindow = document.querySelector('.landing__image-modal');

function fillImageModalWindow(link) {
  const imageInModal = imageModalWindow.querySelector('.landing__image-modal-img');
  imageInModal.src = link;
}

function openImageModalWindow() {
  imageModalWindow.classList.add('landing__image-modal_open');
  document.addEventListener('keydown', closeImageModalWindowByEsc);
}

function closeImageModalWindow() {
  imageModalWindow.classList.remove('landing__image-modal_open');
  document.removeEventListener('keydown', closeImageModalWindowByEsc);
}

function closeImageModalWindowByEsc(evt) {
  if(evt.key === "Escape") {
    closeImageModalWindow()
  }
}

buttonsOpenImageModal?.forEach((button) => {
  button.addEventListener('click', () => {
    fillImageModalWindow(button.src)
    openImageModalWindow();
  })
});

imageModalWindow?.addEventListener('mousedown', (evt) => {
  if (evt.target.classList.contains('landing__image-modal_open')) {
    closeImageModalWindow()
  }
  if (evt.target.classList.contains('landing__image-modal-close-btn')) {
    closeImageModalWindow()
  }
});

