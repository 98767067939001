const stars = [...document.querySelectorAll('.modal-window__inner .star')];

stars.map((star, index) => {
  const clickHandler = () => {
    const rating = document.querySelector('.js-rating');

    let starIndex = index + 1;
    
    rating.classList.remove('value-1', 'value-2', 'value-3', 'value-4', 'value-5');
    rating.classList.add('value-' + starIndex);
  };

  star?.addEventListener('click', clickHandler);
});
