const trainerPhoto = document.querySelector('.trainer__photo');
const trainerHeader = document.querySelector('.trainer__header');

const TRAINER_HEADER_COLOR_TRANSPERENT = "transparent";
const TRAINER_HEADER_COLOR_WHITE = "#FFFFFF";
const TRAINER_HEADER_COLOR_GRAY = "#E5E5E2";

let trainerPhotoHeight;

const setTrainerHeaderColor = (color) => {
  trainerHeader.style = `background-color: ${color}`
};

const handleWindowMobileScroll = () => {
  if (window.scrollY > trainerHeader.clientHeight && window.scrollY < trainerHeader.clientHeight + trainerPhotoHeight) {
    setTrainerHeaderColor(TRAINER_HEADER_COLOR_TRANSPERENT);
  } else if (window.scrollY > trainerHeader.clientHeight + trainerPhotoHeight) {
    setTrainerHeaderColor(TRAINER_HEADER_COLOR_WHITE);
  } else {
    setTrainerHeaderColor(TRAINER_HEADER_COLOR_GRAY);
  }
};

const handleWindowResize = () => {
  trainerPhotoHeight = trainerPhoto.clientHeight;
  if(window.innerWidth <= 1024) {
    window.addEventListener('scroll', handleWindowMobileScroll);
    setTrainerHeaderColor(TRAINER_HEADER_COLOR_GRAY);
  } else {
    window.removeEventListener('scroll', handleWindowMobileScroll);
    setTrainerHeaderColor(TRAINER_HEADER_COLOR_WHITE);
  }
};

if(trainerPhoto) {
  window.addEventListener('resize', handleWindowResize);

  document.addEventListener('DOMContentLoaded', () => {
    trainerPhotoHeight = trainerPhoto.clientHeight;

    if(window.innerWidth < 1024) {
      handleWindowMobileScroll();
      window.addEventListener('scroll', handleWindowMobileScroll);
    };
  });
};

const trainerDescription = document.querySelector('.trainer__description');
const TRAINER_DESCRIPTION_INITIAL_HEIGHT = 140;

const buttonOpenTrainerDescription = document.querySelector('.trainer__button-read-more');

buttonOpenTrainerDescription?.addEventListener('click', () => {
  if(trainerDescription.classList.contains("trainer__description_content_visible")) {
    trainerDescription.classList.remove("trainer__description_content_visible")
    buttonOpenTrainerDescription.textContent = "Читать дальше";
    trainerDescription.style.maxHeight = TRAINER_DESCRIPTION_INITIAL_HEIGHT + "px"
  } else {
    trainerDescription.classList.add("trainer__description_content_visible")
    buttonOpenTrainerDescription.textContent = "Скрыть";
    trainerDescription.style.maxHeight = trainerDescription.scrollHeight + "px"
  }
})
