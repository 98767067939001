const allCheckboxesTrigger = document.getElementById('show-all');
const checkboxesList = document.getElementById('checkboxes-list');

const showAllCheckboxes = () => {
  if (allCheckboxesTrigger) {
    if (allCheckboxesTrigger.checked) {
      checkboxesList.classList.add('full-list');
    } else {
      checkboxesList.classList.remove('full-list');
    }
  }
};

allCheckboxesTrigger?.addEventListener('click', showAllCheckboxes);

showAllCheckboxes();
