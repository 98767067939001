const searchInput = document.getElementById('search-input');
const clearInputTrigger = document.getElementById('clear-input');
const listTrainers = document.querySelectorAll('.mobile-filters__list-trainers .checkboxes__input');

function checkValue() {
  if(searchInput.value) {
    clearInputTrigger.classList.add('clear-input--show');
  } else {
    clearInputTrigger.classList.remove('clear-input--show');
  }
};

export function defaultTrainers() {
  listTrainers.forEach(item => {
    item.parentElement.style.display = "inline-flex";
  });
};

export function clearInput() {
  searchInput.value = '';
};

function searchTrainers(event) {
  listTrainers.forEach(item => {
    if (item.value.toLowerCase().includes(event.target.value.toLowerCase())) {
      item.parentElement.style.display = "inline-flex";
    } else {
      item.parentElement.style.display = "none";
    }
  });
};

clearInputTrigger?.addEventListener('click', function handleClick() {
  searchInput.focus();
  defaultTrainers();
  clearInput();
  checkValue();
}); 

searchInput?.addEventListener('input', checkValue);

searchInput?.addEventListener('input', (event) => {
  searchTrainers(event);
});
