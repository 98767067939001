window.onscroll = () => onScrollFunction();

function onScrollFunction() {
  const header = document.getElementById("header");

  if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
    header.classList.add("header--scrolled");
  }

  if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
    header.classList.add("header--fixed");
  }

  if (window.pageYOffset === 0) {
    header.classList.remove("header--scrolled", "header--fixed");
  }
}
