// Таймер шаг 2
// const timerVal = document.getElementById('send-again-timer');
// const getNewCode = document.getElementById('get-new-code');

// function initTimer() {
//   if (timerVal) {
//     let seconds = timerVal.dataset.time;  
//     timerVal.textContent = `Получить новый код через ${seconds} секунд`;

//     const timer = setInterval(() => {
//       if(seconds < 1) {
//         timerVal.style.display = 'none';
//         getNewCode.style.display = 'block';
//         clearInterval(timer);
//       }
      
//       timerVal.textContent = `Получить новый код через ${seconds} секунд`;
//       seconds -= 1;
//     }, 1000);
//   }
// }

// window.initTimer = initTimer;

// function newTimer() {
//   initTimer();
//   initFocusSmsCode();
//   timerVal.style.display = 'block';
//   getNewCode.style.display = 'none';
// }

// getNewCode?.addEventListener('click', newTimer);

// // Таймер шаг 3
// const timerValSecond = document.getElementById('send-again-timer-second');
// const getNewCodeSecond = document.getElementById('get-new-code-second');

// function initTimerSecond() {
//   if (timerValSecond) {
//     let secondsSecond = timerValSecond.dataset.time;  
//     timerValSecond.textContent = `Получить новый код через ${secondsSecond} секунд`;

//     const timerSecond = setInterval(() => {
//       if(secondsSecond < 1) {
//         timerValSecond.style.display = 'none';
//         getNewCodeSecond.style.display = 'block';
//         clearInterval(timerSecond);
//       }
      
//       timerValSecond.textContent = `Получить новый код через ${secondsSecond} секунд`;
//       secondsSecond -= 1;
//     }, 1000);
//   }
// }

// window.initTimerSecond = initTimerSecond;

// function newTimerSecond() {
//   initTimerSecond();
//   initFocusSmsCode();
//   timerValSecond.style.display = 'block';
//   getNewCodeSecond.style.display = 'none';
// }

// getNewCodeSecond?.addEventListener('click', newTimerSecond);
