/*** Document Ready */
document.addEventListener('DOMContentLoaded',() =>
{
	mobileMenu_init();

	window.addEventListener('resize', () =>
	{
		mobileMenu_init();
	});
});

/*** Mobile Menu */
function mobileMenu_show()
{
	return (window.matchMedia('(max-width: 1023px)').matches) ? true : false;
}

function mobileMenu_init()
{
	if(mobileMenu_show())
	{
		if(document.querySelector('#mobile-menu') == null)
		{
			document.querySelector('.header .header-nav').innerHTML += '<button id="mobile-menu" class="mobile-menu" type="button" data-aos="fade-zoom-in" data-aos-once="true"><div class="line"></div><div class="line"></div><div class="line"></div></button>';
			
			mobileMenu_build();
			document.querySelector('#mobile-menu').addEventListener('click', function(event) {
				event.preventDefault();
				mobileMenu_toggle();
			});
		}
	}
	else
	{
		mobileMenu_destroy();
	}
}

function mobileMenu_build()
{
	let menu_content = document.createElement('div');
	menu_content.setAttribute('id', 'mobile-menu-content');
	document.body.append(menu_content);
	
	let menu_content_overflow = document.createElement('div');
	menu_content.appendChild(menu_content_overflow);
	
	let navigation_clone = document.getElementById('mobile-nav').cloneNode(true);
	menu_content_overflow.appendChild(navigation_clone);
}

function mobileMenu_toggle()
{
	document.getElementById('header-nav').classList.toggle('open');
	document.getElementById('mobile-menu').classList.toggle('open');
	document.getElementById('mobile-menu-content').classList.toggle('open');
}

function mobileMenu_destroy()
{
	let mobileMenu = document.getElementById('mobile-menu');
	if(mobileMenu != null)
	{
		mobileMenu.remove();
		document.getElementById('mobile-menu-content').remove();
	}
}
