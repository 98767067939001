import { defaultTrainers, clearInput } from './clear-search';

const filtersTrigger = document.getElementById('show-filters');
const closeFiltersTrigger = document.getElementById('close-filters');
const filtersBackdropFTrigger = document.getElementById('filters-backdrop');
const resetFiltersTrigger = document.getElementById('reset-filters');
const filters = document.getElementById('filters');
const body = document.querySelector('body');
const mobileFiltersForm = document.getElementById('mobile-filters-form');
const mobileFiltersTopPanel = document.getElementById('mobile-filters-top-panel');
const mobileFiltersBottomPanel = document.getElementById('mobile-filters-bottom-panel');
const mobileFiltersListTrainers =document.getElementById('mobile-filters-list-trainers');

const verticalScrollbar = () => {
  setTimeout(() => {
    const hasVerticalScrollbar = mobileFiltersForm.scrollHeight > mobileFiltersForm.clientHeight;

    if (hasVerticalScrollbar) {
      mobileFiltersBottomPanel.classList.add("mobile-filters__bottom-panel--scrolled");
    } else {
      mobileFiltersBottomPanel.classList.remove("mobile-filters__bottom-panel--scrolled");  
    }
  }, "350");
};

const resizeListTrainers = new ResizeObserver(() => {
  verticalScrollbar();
});

if (mobileFiltersListTrainers) {
  resizeListTrainers.observe(mobileFiltersListTrainers);
}

filtersTrigger?.addEventListener('click', () => {
  body.style.overflow = 'hidden';
  filters.classList.add('mobile-filters--show');
});

const hideFilters = () => {
  body.style.overflow = 'auto';
  filters.classList.add('mobile-filters--hiding');

  setTimeout(() => {
    filters.classList.remove('mobile-filters--show', 'mobile-filters--hiding');
  }, "350");
};

closeFiltersTrigger?.addEventListener('click', hideFilters);

filtersBackdropFTrigger?.addEventListener('click', hideFilters);

mobileFiltersForm?.addEventListener('scroll', () => {
  if (mobileFiltersForm.scrollTop > 0) {
    mobileFiltersTopPanel.classList.add("mobile-filters__top-panel--scrolled");
  } else {
    mobileFiltersTopPanel.classList.remove("mobile-filters__top-panel--scrolled");
  }
  if (mobileFiltersForm.scrollTop + mobileFiltersForm.offsetHeight >= mobileFiltersForm.scrollHeight) {
    mobileFiltersBottomPanel.classList.remove("mobile-filters__bottom-panel--scrolled");
  } else {
    mobileFiltersBottomPanel.classList.add("mobile-filters__bottom-panel--scrolled");
  }
});

const checkedInputs = () => {
  const checkboxes = document.querySelectorAll(".checkboxes__input--mobile");

  checkboxes.forEach(item => {
    if (item) {
      item.checked = false;
    } 
  });

  clearInput();
  defaultTrainers();
};

resetFiltersTrigger?.addEventListener('click', checkedInputs);
