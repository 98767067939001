const loginTrigger = document.getElementById('login-trigger');
const hideLoginTrigger = document.getElementById('hide-login-trigger');
const loginScreen = document.getElementById('login');
const body = document.querySelector('body');
const phoneCode = document.querySelector('.login__input');

function hideLoginScreen() {
  loginScreen.classList.add('login--hide');

  setTimeout(() => {
    loginScreen.classList.remove('login--hide', 'login--show');
    loginScreen.style.display = 'none';
    body.style.overflow = 'auto';
  }, 300);
}

function showLoginScreen() {
  loginScreen.style.display = 'block';

  if (phoneCode) {
    phoneCode.focus();
  }

  setTimeout(() => {
    loginScreen.classList.add('login--show');
    body.style.overflow = 'hidden';
  }, 100);
};

window.showLoginScreen = showLoginScreen;

loginTrigger?.addEventListener("click", showLoginScreen);
hideLoginTrigger?.addEventListener("click", hideLoginScreen);

// const smsFirst = document.getElementById('sms-1');
// const smsLast = document.getElementById('sms-4');
// const fields = document.querySelectorAll('.login__sms-input');

// function handleInputField({ target }) {
//   const value = target.value.slice(0, 1);
//   target.value = value;
  
//   const step = value ? 1 : -1;
//   const fieldIndex = [...fields].findIndex((field) => field === target);
  
//   const focusToIndex = fieldIndex + step;

//   if (focusToIndex < 0 || focusToIndex >= fields.length) return;

//   if (target.value !== ' ') {
//     fields[focusToIndex].removeAttribute('disabled');
//     fields[focusToIndex].focus();
//   }
// }

// fields.forEach((field) => {
//   field.addEventListener('input', handleInputField);
// });

// document.addEventListener('paste', function(e) {
//   if (e.target.type === 'number') {
//    var data = e.clipboardData.getData('Text');
//    data = data.split('');
//    [].forEach.call(document.querySelectorAll('.login__sms-input'), (node, index) => {
//       node.value = data[index];
//       node.removeAttribute('disabled');
//       smsLast.focus();
//     });
//   }
// });

// function initFocusSmsCode() {
//   if (smsFirst) {
//     smsFirst.focus();
//   }
// }

// window.initFocusSmsCode = initFocusSmsCode;
