import './js/menu';
import './js/swiper';
import './js/modal';
import './js/on-scroll';
import './js/aos-init';
import './js/calendar-tabs';
import './js/show-checkboxes';
import './js/mobile-filters';
import './js/virtual-select';
import './js/clear-search';
import './js/timer';
import './js/login';
import './js/scroll-to-about';
import './js/play-video';
import './js/registration-tabs';
import './js/studio-tabs';
import './js/stars';
import './js/circle-progress';
import './js/landing';
import './js/gift-card';
import './js/trainer';
