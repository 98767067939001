import { swiperReviews } from './swiper';
import '../../node_modules/video.js/dist/video-js.css';
import videojs from "video.js";

document.addEventListener('DOMContentLoaded', function() {
  const videoItWillBe = document.getElementById("video-it-will-be");

  if (videoItWillBe) {
    videojs(videoItWillBe);
  }

  const swiperContainer = document.querySelector(".swiper-reviews");
  const videosList = document.querySelectorAll(".reviews__card-video");

  swiperContainer?.addEventListener('mouseenter', function() {
    swiperReviews.autoplay.stop();
  });
  
  swiperContainer?.addEventListener('mouseleave', function() {
    swiperReviews.autoplay.start();
  });

  videosList.forEach(function(video) {
    var myPlayer = videojs(video);

    function clickHandler() {
      if (myPlayer.paused()) {
        myPlayer.pause();
      }

      else {
        myPlayer.play();
        swiperReviews.autoplay.stop();
        swiperContainer.addEventListener('mouseleave', function() {
          swiperReviews.autoplay.stop();
        });
      }

      myPlayer.on('playing', function(e) {
        videosList.forEach(function(video) {
          var myPlayer = videojs(video);

          if (e.target.id !== myPlayer.id_) {
            myPlayer.pause();
          }
        });
      });

      myPlayer.on('ended', function() {
        swiperReviews.autoplay.start();
        swiperContainer.addEventListener('mouseleave', function() {
          swiperReviews.autoplay.start();
        });
      });
    }

    myPlayer.on('click', clickHandler);
  });
});
