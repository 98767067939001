(function() {
  "use strict";

  const backdrop = document.querySelector('#modal-backdrop');
  document.addEventListener('click', modalHandler);
	document.addEventListener('keydown', modalHandler);

  function modalHandler(e) {
    const modalBtnOpen = e.target.closest('.js-modal');
    if (modalBtnOpen) {
      e.preventDefault();
      const modalSelector = modalBtnOpen.dataset.modal;
      showModal(document.querySelector(modalSelector));
    }

    const modalBtnClose = e.target.closest('.js-modal-window-close');
    if (modalBtnClose) {
      e.preventDefault();
      hideModal(modalBtnClose.closest('.modal-window'));
    }

    if (e.target.matches('#modal-backdrop')) {
      hideModal(document.querySelector('.modal-window.modal-window--show'));
    }
    
    if (e.key === 'Escape') {
      hideModal(document.querySelector('.modal-window.modal-window--show'));
    }
  }

  function showModal(modalElem) {
    modalElem.classList.add('modal-window--show');
    backdrop.classList.remove('modal-hidden');
  }

  function hideModal(modalElem) {
    modalElem.classList.remove('modal-window--show');
    backdrop.classList.add('modal-hidden');
  }

  function showModalAjax() {
    const backdrop = document.querySelector('#modal-backdrop');
    const modalElem = document.querySelector('#modal_2');
    modalElem.classList.add('modal-window--show');
    backdrop.classList.remove('modal-hidden');
  }

  window.showModalAjax = showModalAjax;
})();