import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper';
import '../../node_modules/swiper/swiper-bundle.css';

const swiperHero = new Swiper('.swiper-hero', {
  modules: [Navigation, Pagination],
  slidesPerView: '1.2',
  spaceBetween: 20,
  speed: 300,
  initialSlide: '0',
  pagination: { el: '.swiper-pagination', clickable: true },
  navigation: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next'
  },
  breakpoints: {
    720: {
      slidesPerView: 'auto',
      spaceBetween: 30,
    }
  }
});

const swiperTrainers = new Swiper('.swiper-trainers', {
  modules: [Navigation],
  slidesPerView: 'auto',
  spaceBetween: 20,
  initialSlide: '0',
  centeredSlides: true,
  speed: 300,
  navigation: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next'
  },
  breakpoints: {
    720: {
      centeredSlides: false,
      spaceBetween: 30,
    },

    1140: {
      centeredSlides: false,
    }
  }
});

const slideLabel = document.querySelectorAll('.swiper-studio [data-name]');

const swiperStudio = new Swiper('.swiper-studio', {
  modules: [Pagination, EffectFade],
  slidesPerView: 1,
  spaceBetween: 0,
  initialSlide: '0',
  centeredSlides: true,
  effect: 'fade',
  speed: 300,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: function (index, className) {
      return '<li class="' + className + '">' + '<div class="swiper-pagination-bullet-inner">' + slideLabel[index].dataset.name + '</div>' + '</li>';
    }
  }
});

export const swiperReviews = new Swiper('.swiper-reviews', {
  modules: [Navigation, Autoplay],
  slidesPerView: 'auto',
  spaceBetween: 20,
  loop: true,
  centeredSlides: true,
  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },
  speed: 300,
  navigation: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next'
  },
  breakpoints: {
    720: {
      spaceBetween: 30,
    }
  },
  on: {
    click() {
      swiperReviews.slideTo(this.clickedIndex);
    }
  },
});

const swiperProfile = new Swiper('.swiper-profile', {
  modules: [Navigation],
  slidesPerView: 'auto',
  spaceBetween: 15,
  initialSlide: '0',
  centeredSlides: false,
  speed: 300,
  navigation: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next'
  }
});

// Инициализация слайдера при определенной ширине экрана на странице подарочных карт
let cardDesignSlider = null;
let mediaSize = 768;

const cardDesignSliderWrapper = document.querySelector('.get-gift-card__card-design');

function cardDesignSliderInit () {
  if (!cardDesignSlider) {
    cardDesignSliderWrapper.classList.add('swiper-wrapper')
    cardDesignSlider = new Swiper('.get-gift-card__swiper', {
      modules: [Pagination],
      slidesPerView: '1.2',
      spaceBetween: 27,
      pagination: { el: '.swiper-pagination', clickable: true },
    });
  }
}

function cardDesignSliderDestroy () {
  if (cardDesignSlider) {
    cardDesignSlider.destroy();
    cardDesignSlider = null;
    cardDesignSliderWrapper.classList.remove('swiper-wrapper')
  }
}

function toggleCardDesignSlider () {
  if (cardDesignSliderWrapper) {
    const windowWidth = window.innerWidth;
    
    if (windowWidth <= mediaSize) {
      cardDesignSliderInit()
    } else {
      cardDesignSliderDestroy()
    }
  }
}

window.addEventListener('resize', toggleCardDesignSlider);
document.addEventListener("DOMContentLoaded", toggleCardDesignSlider);
