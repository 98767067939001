import CircleProgress from 'js-circle-progress';

const percentProgress = document.querySelector('.progress');

if (percentProgress !== null) {
  const cpInitValue = percentProgress.dataset.value;

  const cp = new CircleProgress('.progress', {
    value: cpInitValue,
    max: 100,
    textFormat: 'percent',
  });

  const observer = new MutationObserver(function() {
    const cpNewValue = percentProgress.dataset.value;

    cp.attr({
      value: cpNewValue,
    });
  });

  const config = { attributes: true }

  observer.observe(percentProgress, config);
}

