const content = document.querySelectorAll('.registration__tab');
const tab = document.querySelectorAll('.registration__tabs-trigger');
const tabs = document.querySelector('.registration__tabs');

function hideTabContent() {
  content.forEach(item => {
    item.style.display = 'none';
  });
  tab.forEach(item => {
    item.classList.remove('registration__tabs-trigger--active');
  });
}

function showTabContent(i = 0) {
  if (content[i] && tab[i]) {
    content[i].style.display = 'block';
    tab[i].classList.add('registration__tabs-trigger--active');
  }
}

// hideTabContent();
// showTabContent();

tabs?.addEventListener("click", (e) => {
  const target = e.target
  if (target) {
    tab.forEach((item, i) => {
      if (target == item) {
        hideTabContent();
        showTabContent(i);
      }
    })
  }
})
