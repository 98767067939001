// const content = document.querySelectorAll('.timetable__tab');
// const tab = document.querySelectorAll('.calendar__tabs-trigger');
const slider = document.querySelector('.calendar__tabs');

// function hideTabContent() {
//   // content.forEach(item => {
//   //   item.style.display = 'none';
//   // });
//   tab.forEach(item => {
//     item.classList.remove('calendar__tabs-trigger--active');
//   });
// }

// function showTabContent(i) {
//   if (content[i] && tab[i]) {
//     // content[i].style.display = 'block';
//     tab[i].classList.add('calendar__tabs-trigger--active');
//   }
// }

// hideTabContent();
// showTabContent();

// slider?.addEventListener("click", (e) => {
//   const target = e.target
//   if (target) {
//     tab.forEach((item, i) => {
//       if (target == item) {
//         hideTabContent();
//         showTabContent(i);
//       }
//     })
//   }
// })

document.querySelectorAll('.calendar__tabs-trigger').forEach(item => {
  item.addEventListener('click', event => {
    document.querySelectorAll('.calendar__tabs-trigger').forEach(i => {
      i.classList.remove('calendar__tabs-trigger--active')
    });
    item.classList.add('calendar__tabs-trigger--active');
  })
})

const nextButton = document.querySelector('.calendar__button-next');
const prevButton = document.querySelector('.calendar__button-prev');

function handleNextButtonClick() {
  slider.scrollTo({left: 2000, behavior: 'smooth'});
  prevButton.classList.remove('calendar__button--disabled');
  nextButton.classList.add('calendar__button--disabled');
}

function handlePrevButtonClick() {
  slider.scrollTo({left: -2000, behavior: 'smooth'});
  nextButton.classList.remove('calendar__button--disabled');
  prevButton.classList.add('calendar__button--disabled');
}

nextButton?.addEventListener('click', handleNextButtonClick);
prevButton?.addEventListener('click', handlePrevButtonClick);

slider?.addEventListener("touchstart", handleTouchStart, false);
slider?.addEventListener("touchmove", handleTouchEnd, false);

let initialX = null;

function handleTouchStart(e) {
  initialX = e.touches[0].clientX;
};

function handleTouchEnd(e) {
  if (initialX === null) {
    return;
  }

  let currentX = e.touches[0].clientX;
  let diffX = initialX - currentX;
  
  if (diffX > 0) {
    handleNextButtonClick();
  } else {
    handlePrevButtonClick();
  }

  initialX = null;
  e.preventDefault();
};
