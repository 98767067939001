import AirDatepicker from 'air-datepicker';
import "../../node_modules/air-datepicker/air-datepicker.css";
import IMask from 'imask';

const datepicker = document.getElementById('airdatepicker');

let dp =  new AirDatepicker(datepicker, {
  autoClose: true,
  minDate: new Date(),
  classes: "get-gift-card__calendar",
  prevHtml: '<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.18311 1.675L2.35811 5.5L6.18311 9.325L4.99977 10.5L-0.000227928 5.5L4.99977 0.5L6.18311 1.675Z" fill="#C6C6CB"/></svg>',
  nextHtml: '<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.816895 9.325L4.6419 5.5L0.816895 1.675L2.00023 0.5L7.00023 5.5L2.00023 10.5L0.816895 9.325Z" fill="#C6C6CB"/></svg>',
  onSelect({date}) {
    const dateSrc = date.toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric'});
    dateMask.value = dateSrc;
  }
});

const dateInput = document.querySelector('.get-gift-card__field-input_date');

let dateMask;
if(dateInput) {
  dateMask = IMask(
  dateInput ,
  {
    mask: Date,
    min: new Date(Date.now()-86400000),
    max: new Date(2025, 0, 1),
  }
)};

datepicker?.addEventListener('change', () => {
    let mydate = dateMask.value.split('.');
    mydate = new Date(mydate[2], mydate[1] - 1, mydate[0]);
    dp.selectDate(mydate)
  }
);

const phoneInputs = document.querySelectorAll('.get-gift-card__field-input_number');

phoneInputs?.forEach((input) => {
  const phoneMask = IMask(
    input, {
      mask: '+ {7} (000) 000-00-00'
    });
  }
);

const content = document.querySelectorAll('.get-gift-card__tab');
const tab = document.querySelectorAll('.get-gift-card-tab-trigger');
const tabs = document.querySelector('.get-gift-card__switcher');

function hideStudioTabContent() {
  content.forEach(item => {
    item.style.display = 'none';
  });
  tab.forEach(item => {
    item.classList.remove('switcher__button--active');
  });
};

function showStudioTabContent(i = 0) {
  if (content[i] && tab[i]) {
    content[i].style.display = 'block';
    tab[i].classList.add('switcher__button--active');
  }
};

hideStudioTabContent();
showStudioTabContent();

tabs?.addEventListener("click", (e) => {
  const target = e.target
  if (target) {
    tab.forEach((item, i) => {
      if (target == item) {
        hideStudioTabContent();
        showStudioTabContent(i);
      }
    })
  }
});